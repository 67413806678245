import './App.css';
import * as React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Card,

  CardContent,
  CardMedia,
  Container,
  Grid,
  ThemeProvider,


} from "@material-ui/core";
import wingo_booth from "./img/wingo-booth.jpg";
import office01 from "./img/office.jpg";
import office02 from "./img/office2.jpg";
import kukumaru from "./img/kukumaru.jpg";
import shinbun from "./img/990.jpg";
import typing from  "./img/tc.jpg";
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "fffff9",
      main: "#ff972d",
      dark: "cbb895",
      contrastText: "#fff",
    },
    secondary: {
      light: "#773431",
      main: "#f44336",
      dark: "2a0000",
      contrastText: "#fff",
    },
    backgroundcolor: {
      default: "#ff0000",
    },
  },
});
function App() {
  return (

    <ThemeProvider theme={theme}>
    <AppBar>
    </AppBar>
      <title>Home Page</title>
      <Container width="1200px" m={3} p={3}>
      <Card styles={{color:"white",margin:10}}>
        <Grid container spacing={3} m={3} p={3}>

          <Grid item xs={12} m={3} p={3}>
            <Card height="10px" />
          </Grid>
          <Grid item xl={12} xs={12}>
            <CardContent>
              <font color="gray">
                <h1>
                  <i>
                    「ゲームを通じた教育サービス」を株式会社ソリスト合唱団は提供いたします。
                  </i>
                </h1>
                合唱団の中で独唱するソリストのような個性豊かな人材を束ねる会社、が社名の由来です。
              </font>
            </CardContent>
          </Grid>
          <Grid item xl={4} xs={12}>
            <CardMedia>
              <img src={wingo_booth} alt="wingo_booth" height="auto" width="100%" />
            </CardMedia>
            <CardContent>
              <h4>弊社が日本での正規代理店を営んでいるWingo</h4>
              <article>
              <a href="https://print-games.com/">代理店サービス「プリントゲームス」</a><br/>
              <h4>ゲーム専門印刷工場Wingo</h4>

              中国の広東省にあるゲーム専門印刷工場のWingoと創業前から取引しております。
              国内の半分以下のコストでコストパフォーマンス良く、テーブルゲームを製造可能です。
              カードゲーム、ボードゲーム、ダイス、ポーン、フィギュアなどゲームアクセサリーの
              製造をお任せください。

              弊社の製品もWingoで製造しております。
              </article>

              <h4>Wingo代理店サービス「プリントゲームス」</h4>
<article>
              名前を聞けば業界であれば、誰もが知るパブリッシャーにも継続的にWingoは
              お取引して頂いております。

              その代理店サービス「プリントゲームス」は中国との取引が不安なお客様でも
              安心してご利用頂ける。ワンストップのサービスです。

              入稿から試作、色校正、本制作、そしてお届け、関税の立て替えまで含んだトータルコストを提示いたします。
              是非、一度お見積もりのためのゲーム仕様をプリントゲームスまでください。
              </article>

            </CardContent>
          </Grid>

          <Grid item xl={4} xs={12}>
            <CardMedia>
              <img src={office01} alt="office" height="auto" width="100%" />
            </CardMedia>
            <CardContent>
            <h4>株式会社ソリスト合唱団</h4>

            人と人とをつなげる力がエンターテインメントにはある、と信じて会社を立ち上げました。一方で人生を成功に導くためにはたゆまぬ自己研鑽が必要だとも感じております。
            楽しく人と人とがコミュニケーションしながら、お互いを高めていけるような「教育ゲーム」を提供して参りたいと存じます。
              <h4>事業内容</h4>
              ・アナログゲームの企画・製造・販売
              <br />
              ・ウェブゲームの企画・開発・サービスの提供
              <br />
              ・株式投資サポートソフトウェアの提供
              <br />
              <h4>代表取締役　沖井広行</h4>
              　はじめまして、ソリスト合唱団という会社名には、合唱団の中で独唱するソリストのように集団の中で個性を発揮する社員が活躍する会社という意味が込められております。 ゲームと教育サービスで皆様に楽しい生活を届けられれば嬉しく思います。
              <h4>連絡先</h4>
              okinoi@sorisuto.page<br />
              okinoi@sorisuto.com<br />
              070-4036-4649<br />
              03-6820-2747<br />

              <h4>資本金</h4>
              350万円
              <h4>設立日</h4>
              2015年1月15日
              <h4>本店所在地</h4>
              東京都立川市若葉町4-5-10
            </CardContent>
          </Grid>
          <Grid item xl={4} xs={12}>
            <CardMedia>
              <img src={office02} alt="office_2" height="auto" width="100%" />
            </CardMedia>
            <CardContent>
              <h4>アナログゲームのコンサルティング業務</h4>
              <article>テーブルゲームの企画・製造・流通のご相談に乗ることが可能です。</article>

              <article>テーブルゲームを作りたいが、ゲームルールをエンドユーザーに満足いただけるように用意するにはどうすれば良いのか。
              作成したゲームを問屋を介して大手家電量販店などに陳列したい。などのお悩みを解決いたします。</article>
            </CardContent>
          </Grid>

          <Grid item xl={12} xs={12}>
            <CardContent>
              <font color="gray">
                <h1>
                  <i>
                    エンターテインメントを潤滑油に人と人とがつながれる社会を目指します。　
                  </i>
                </h1>
                それぞれ異なった個性を持っていても、エンターテインメントを通じて、コミュニケーションする。
              </font>
            </CardContent>
          </Grid>

          <Grid item xl={4} xs={12}>
            <CardMedia>
              <img src={shinbun} alt="新聞の記事" height="auto" width="100%" />
            </CardMedia>
            <CardContent>
              <h4>新聞に大きく取り上げられた弊社製品のくくまる(990)</h4>
              <article></article>
            </CardContent>
          </Grid>

          <Grid item xl={4} xs={12}>
            <CardMedia>
              <img src={kukumaru} alt="九九の知育ゲーム" height="auto" width="100%" />
            </CardMedia>
            <CardContent>
              <h4>弊社で製造を手配した九九の知育ゲーム「くくまる（９９０）」</h4>
              <article></article>
            </CardContent>
          </Grid>



        <Grid item xl={4}>
          <CardMedia>
            <img src={typing} alt="タイピング練習サイト" height="auto" width="100%" />
          </CardMedia>
          <CardContent>
            <h4>タイピング練習サイトの運営</h4>
            <article>タイピング練習サイトTypingCafeの運営をしております。</article>
            <a href="https://typing.cafe/">Typing Cafeはこちらです。</a>

          </CardContent>
        </Grid>
</Grid>
</Card>
      </Container>
    </ThemeProvider>
  );
}

export default App;
